import React from "react";
import styled from "styled-components"

const Amoeba = styled.svg `
// Safari needs a little extra help...
  width: 104%;

`;

const AmoebaSlim = (props) => {
    return (

<Amoeba xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8191.99979 790">
      <path fill={props.fillColor} id="Path_6032" data-name="Path 6032" class="cls-1" d="M8190.771,0C7980.4822,164.33152,7299.80942,204.39039,6939.115,320.30719c-367.08359,117.5552-1333.00236,82.57535-1835.82715,181.207-366.26439,71.68-1205.28877,14.336-1548.37,34.57025-954.61338,56.44285-1267.71157-86.8352-2111.48755-136.151C747.60188,359.46494,353.15712,163.92192,0,25.31328v769.9661H8191.99979V0Z"/>
</Amoeba>
    )
}
export default AmoebaSlim
