import React from "react";
import styled from "styled-components"

const Amoeba = styled.svg `
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const AmoebaBottom = (props) => {
    return (

<Amoeba xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1943.80005 795.24973" width="100%" >
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g id="Layer_2-2" data-name="Layer 2">
        <g id="Group_665" data-name="Group 665">
          <path fill={props.fillColor} id="Path_6032" data-name="Path 6032" class="cls-1" d="M1943.5,0c-49.9,39-211.4,48.5-297,76-87.1,27.9-316.30005,19.6-435.6,43-86.9,17-286,3.4-367.4,8.2-226.5,13.4-300.8-20.6-501-32.3C177.4,85.3,83.8,38.9,0,6V795.24973H1943.80005V0Z"/>
        </g>
      </g>
    </g>
  </g>
</Amoeba>
    )
}
export default AmoebaBottom
