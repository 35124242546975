import React from "react";
import styled from "styled-components"

const Amoeba = styled.svg `
  width: auto;
  height: 100%;
  
  /* .cls-1 {
        fill: #434081;
      } */
`;

const HeroAmoeba = (props) => {
    return (

<Amoeba className="heroAmoeba" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1272.91003 900">
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g id="Group_658" data-name="Group 658">
        <path fill={props.fillColor} id="Path_6029" data-name="Path 6029" class="cls-1" d="M1272.91,899.858C1183.1,876.76,1161.287,802,1098.084,762.346c-64.283-40.331-45.03906-146.451-98.861-201.684-39.223-40.251-7.888-132.442-18.966-170.1-30.843-104.854,47.46594-139.252,74.249-231.946C1076.6,82.145,1183.476,38.819,1259.181,0H0V900H1272.85205C1272.87,899.953,1272.892,899.906,1272.91,899.858Z"/>
      </g>
    </g>
  </g>
</Amoeba>
    )
}

export default HeroAmoeba
