import React from "react"
import { Container } from "react-bootstrap"

import Img from "gatsby-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import HeroAmoeba from "./HeroAmoeba"
import { RichText } from "prismic-reactjs"
import AmoebaBottom from "../Amoebas/AmoebaBottom"

import styled from "styled-components"
import AmoebaSlim from "../Amoebas/AmoebaSlim"

const MobileHero = styled.div`
  display: block;
  width: 100%;
  margin: 0;
  overflow: hidden;
  > div {
    position: relative;
  }

  .amoeba {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .amoebaContainer {
    position: absolute;
    bottom: 0;
  }

  /* @media (min-width: 1024px) {
    display: none;
  } */
`

const MobileHeroTextBlock = styled.div`
    padding: 10px;
    margin-top: -2px;
    background-color: ${props => props.bgColor};
`;

const Hero = styled.div`
  display: none;

  /* @media (min-width: 1024px) {
    display: block;
    position: relative;
    > div:nth-child(2){
      position: absolute;
      top: 0;
      left: 0;
    }
  } */
`

const PageHero = props => {
  return (
    <Container fluid style={{}}>
      <Hero>
        <div>
        <GatsbyImage image={getImage(props.image)} alt={props.alt} />  
          <div style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%'}}>
          <div className="headingContainer" style={{display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', width: '45%'}}>
            <h1 className="heroHeading">{props.heading}</h1>
            <p className="mobileHeroDescription" style={{ color: 'white'}}>
              {props.description}
            </p>
          </div>
            <HeroAmoeba
              style={{ width: "auto", height: "100%" }}
              fillColor={props.fillColor}
            />

          </div>
        </div>
        <div>
       </div>
      </Hero>

      <MobileHero>
        <div>
        <GatsbyImage image={getImage(props.image)} alt={props.alt} />  
        {/* <Img
          fluid={props.mobileImage}
          alt=""
          style={{ height: "100%", maxHeight: "400px" }}
        /> */}
        <div className="amoeba">
          <AmoebaSlim
            style={{ width: "auto", height: "100%" }}
            fillColor={props.fillColor}
          />
        </div>
        </div>
        <MobileHeroTextBlock bgColor={props.bgColor}>
              <h1 className="mobileHeroHeading" style={{textAlign: 'center'}}>{props.heading}</h1>
              <p className="mobileHeroDescription" style={{color: 'white', textAlign: 'center', fontSize: '1.25rem'}}>
                {props.description}
              </p>
            </MobileHeroTextBlock>
      </MobileHero>
    </Container>
  )
}

export default PageHero
